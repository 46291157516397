import Cookies from 'cookies-js';
import Hunt from 'huntjs';

const COOKIE_EXPIRY = 365 * 24 * 60 * 60; // 1 year in seconds

// allow cookie across Curtin sub domains
let domain = 'curtin.edu.au',
    hostname = window.location.hostname;
// on local?
if (['127.0.0.1', '::1'].includes(hostname) || hostname.includes('.local') || hostname.includes('localhost')) {
    domain = hostname;
}
// on staging/int ?
else if (hostname.includes('p183.sites.pressdns.com')) {
    domain = 'p183.sites.pressdns.com';
}
else {
    //clear any segment cookies already set
    setCookie('segment','','localhost:3000',-1);
    setCookie('segment','','p183.sites.pressdns.com',-1);
}

const COOKIE_DOMAIN = domain;


function setCookie(name, value, domain=COOKIE_DOMAIN, expiry=COOKIE_EXPIRY) {
    const opts = {
        domain: domain,
        expires: expiry
    };
    Cookies.set(name, value, opts);
}

function getCookie(name) {
    return Cookies.get(name);
}

//https://jsfiddle.net/jonathansampson/m7G64/
function throttle (callback, limit) {
    var wait = false;                  // Initially, we're not waiting
    return function () {               // We return a throttled function
        if (!wait) {                   // If we're not waiting
            callback.call();           // Execute users function
            wait = true;               // Prevent future invocations
            setTimeout(function () {   // After a period of time
                wait = false;          // And allow future invocations
            }, limit);
        }
    }
}


function matchHeights($items) {
    var maxHeight = 0;

    // reset the height of all items - allows layout changes on screen resize.
    $items.css('height','initial');

    $items.each(function () {
        var thisHeight = jQuery(this).height();
        maxHeight = thisHeight > maxHeight ? thisHeight : maxHeight;
    });

    $items.css('height',maxHeight);
}

function scrollToY(y, duration=300) {
    $('html,body').animate({
        scrollTop: y
    }, duration);
}

function isValidJson(response) {

    let isValidJson = true;
    try {
        JSON.parse(response);
    } catch(error) {
        isValidJson = false;
    }

    return isValidJson;
}

function dynamicModal(title, content) {
    return (`<div class="modal"><h1>${title}</h1><div class="modal__content">${content}</div></div>`);
}
// check if MtkoForms2 is loaded to try and prevent
function hasForms2() {

    if (typeof MktoForms2 === 'undefined') {
        console.log('Unable to initialise MktoForms2');
        return false;
    }

    return true;
}

function isMobile() {
    const screenWidth = window.innerWidth;      

    if (screenWidth <= 1024) {
        return true;
    }

    return false;
}

function itemInView(item, className, throttle = 100) {
    new Hunt(item, {
        enter: function(el) {
            el.classList.add(className);
        },
        leave: function(el) {
            el.classList.remove(className);
        },
        // called every time the element appears and disappears from the viewport 
        persist: true,
        // trigger offset in pixels
        offset: 0,
        // interval that hunt events are throttled by in milliseconds
        throttleInterval: throttle
    });
    
}


// small cross browser function to get keycode from event, used for detecting keypresses for accessibility with various components
function getKeycode(event) {   
    return event.keyCode || event.charCode;
}

export { throttle, matchHeights, scrollToY, COOKIE_EXPIRY, COOKIE_DOMAIN, setCookie, getCookie, isValidJson, dynamicModal, hasForms2, isMobile, itemInView, getKeycode };