
(function($) {
    function cardSpotlights() {
        // each spotlight click
        $('.spotlight-grid .spotlight').click(function() {
            //hide active spotlight
            $('.card.active').removeClass('active');
            // get the relevant card and display it
            let $card = $('#' + $(this).data('card-link'));
            $card.show().addClass('active');
            $('.card__bg').addClass('active');
            // show the close button
            $card.find('.spotlight__toggle').show();
        });

        $('.card__bg, .spotlight__toggle').click(function() {
            $('.spotlight__toggle').hide();
            $('.card__bg.active').removeClass('active');
            $('.card-spotlights .card.active').removeClass('active').attr('style', '');
        });

    }

    $(document).ready(function() {
        if($('.spotlight-grid').length) {
            cardSpotlights();
        }
    });

})(jQuery);

