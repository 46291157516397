class MegaMenu {

    config = {
        parent: null,
        child: null,
        dropdown: null
    }

    constructor(parent, child, dropdown){
        let $this = this;

        // Bind mega menu elements to be used in event handlers

        // Parent link
        $this.config.parent = $(parent);
        // Child link
        $this.config.child = $(child);
        // Mega Menu
        $this.config.dropdown = dropdown;

        // Bind event handlers to navigation elements
        $this.bindEventHandlers($this);
    }

    bindEventHandlers($this){

        $this.config.parent.each(function(i,e){

            // Apply keyboard accessibility to top links
            $(e).find('.global-nav__link--top').on('keydown', (ele) => {

                // if space is pressed open mega menu and toggle aria-expanded attribute to true
                if(ele.key === ' '){
                    ele.preventDefault();

                    // Toggle parent link aria-attribute to indicate closed
                    $(ele.target).attr('aria-expanded', 'true');

                    // Close mega menu
                    $(e).find($this.config.dropdown).addClass('visible');

                // if esc is pressed on a parent link and the mega menu is open. Close mega menu and toggle aria-expanded attribute to false
                } else if((ele.key === 'Escape' && $(e).find($this.config.dropdown).hasClass('visible'))){

                    // Toggle parent link aria-attribute to indicate closed
                    $(ele.target).attr('aria-expanded', 'false');

                    // Close mega menu
                    $(e).find($this.config.dropdown).removeClass('visible');

                // if shift + tab is pressed on a parent link and the mega menu is open. Close mega menu and toggle aria-expanded attribute to false
                } else if(ele.shiftKey && ele.key === 'Tab'){

                    // Toggle parent link aria-attribute to indicate closed
                    $(ele.target).attr('aria-expanded', 'false');

                    // Close mega menu
                    $(e).find($this.config.dropdown).removeClass('visible');
                }
            });
        });

        $($this.config.dropdown).each(function(i,e){
            let $child = $(e).find('a');
            let $last = $child.last();

            $child.each(function(int,ele){
                // if esc is pressed on child links toggle aria-expanded on parent link
               $(ele).on('keydown', (element) => {
                   if(element.key === 'Escape'){

                       // Close mega menu
                       $(e).removeClass('visible');

                       // Toggle parent link aria-attribute to indicate closed
                       $(e).siblings('.global-nav__inner').children('a').attr('aria-expanded', 'false');

                       // Focus on parent link
                       $(e).siblings('.global-nav__inner').children('a').focus();
                   }
               });
            });

            $last.each(function(int,ele){
                // If tapped is pressed on last link child close mega menu and toggle aria-expanded attribute to false
                $(ele).on('keydown', (element) => {
                    if(element.key === 'Tab'){

                        // Close mega menu
                        $(e).removeClass('visible');

                        // Toggle parent link aria-attribute to indicate closed
                        $(e).siblings('.global-nav__inner').children('a').attr('aria-expanded', 'false');
                    }
                });
            });
        });
    }
}

$(() => { mega_menu = new MegaMenu('.global-nav__item--top', '.global-nav__item--child', '.global-nav__dropdown') })