import { setCookie, getCookie } from "./helpers";
/*
header.js is not using ES6 or dependancies. This is so it can be easily transportable to other sites.

make sure you're not editing dist/js/header.js as that's just a copy of src/js/header.js :D
*/


jQuery(function ($) {

    $(document).ready(function() {

        var nav = $('.desktop-nav');

        searchInit();
        searchOpenHandler();
        searchCloseHandler();
        mobileToolsPanelHandler();
        segmentButtonHandler();
        closeMegaMenu();

        var mq = window.matchMedia("(min-width: 1024px)");
        mq.addListener(moveHeaderUtilities);
        moveHeaderUtilities(mq);

        function openDropdownHandler() {

            var subMenuOffset = parseInt(nav.find('ul li ul:first').css('left')); // store the css left propety of a submenu for future resetting

            nav.find('li[data-depth="0"] > a').on('click', function(e) {
                e.stopPropagation();

                var _parent = $(this).parents('li');

                // if clicked link has a sibling dropdown, show it
                if (_parent.hasClass('menu-item-has-children')) {

                    _parent.toggleClass('active')
                        .siblings()
                        .removeClass('active');

                    var subMenu = _parent.find('ul');
                    var submenuMax = _parent.offset().left + subMenu.width();

                    if (_parent.hasClass('active')) {
                        subMenu.find('a')
                            .first()
                            .focus();
                    }

                    // if submenu is overflowing to the right of the window, bring it over as far left as it needs to fit inside window
                    if (submenuMax > $(window).width()) {
                        let overflowWidth = submenuMax - $(window).width() - subMenuOffset;
                        subMenu.css('left', overflowWidth * -1);
                    } else {
                        subMenu.css('left', subMenuOffset);
                    }

                    return false;
                }
            });
        }

        function closeDropdownHandler() {
            nav.find('li[data-depth="0"] > a').on('focus', function(e) {
                nav.find('> ul > li.active').removeClass('active');
            });

            $(window).on('click', function() {
                nav.find('> ul > li.active').removeClass('active');
            });
        }

        function searchInit() {
            $('[data-header-search]').on('click', function(e) {

                var form = $(this).attr('data-header-search');
                var newForm = $(`[data-header-search-form="${form}"]`);

                $('.header-search .active').removeClass('active');

                $(this).addClass('active');

                newForm.addClass('active')
                    .find('input[type=search]')
                    .focus();

            });
        }

        function searchOpenHandler() {
            $('[data-header-search-open]').on('click', function() {
                $('body').addClass('header-search-active');

                // on desktop we want to make sure the height of the search bar matches the height of the header (or auto on mobile)
                var height = mq.matches ? $('.header').outerHeight() : 'auto';
                let $searchBar = $('#header-search');

                $searchBar.css('height', height);

                var searchInput = $searchBar.find('input[type=search]');
                if (searchInput.length) {
                    setTimeout(function() {
                        // set focus on search type buttons
                        searchInput.focus();
                    }, 50);
                }
            });
        }

        function searchCloseHandler() {

            $('body').keydown(function(e) {

                if (e.keyCode === 27 && $('body').hasClass('header-search-active')) {
                    $('body').removeClass('header-search-active');
                }
            });

            $('[data-header-search-close]').on('click', function() {
                $('body').removeClass('header-search-active');
            });
        }


        // if window width less than 768 pause slick slider, else play slider.
        function moveHeaderUtilities(mq) {

            var utils = $('.header__utilities');

            if (utils.length) {
                if (!mq.matches) {
                    utils.insertAfter('.header__logo');
                } else {
                    utils.insertAfter('.header__links');
                }
            }
        }

        function mobileToolsPanelHandler() {
            $('.mobile-tools__head .btn-psnl').click(function() {
                $(this).toggleClass('active');
                let segment = $(this).find('.segment').data('region');
                $('.mobile-tools__panel').toggleClass('active');
                $('.mobile-tools__panel .inner[data-region="'+segment+'"]').toggleClass('active');
                
            })
        }

        function segmentButtonHandler() {
            $('.mobile-tools__panel .button').click(function() {
                let segment = $(this).data('region');
                
                segment === 'dom' ? setCookie('segment', 'int') : setCookie('segment', 'dom');
                //console.log(getCookie('segment'));

                location.reload();
            })
        }

        function stuckClassHandler() {

            var header = $('.header__bottom');
            var headerTop = $('.header__top');
            var stuckStart = headerTop.outerHeight();

            var utils = $('.header__utilities');

            var newlyStuck = false;

            $(window).on('scroll', function(e) {

                // check if nav is stuck
                if ($(window).scrollTop() >= stuckStart || $('body').hasClass('admin-bar')) {
                    header.addClass('stuck');
                    if (mq.matches) {

                        $('.header__top').css('min-height', stuckStart);
                        if (!newlyStuck) {
                            utils.hide()
                                .insertAfter('.desktop-nav')
                                .fadeIn(300);
                        }
                        newlyStuck = true;
                    }
                } else {
                    header.removeClass('stuck');
                    if (mq.matches) {
                        newlyStuck = false;
                        utils.insertAfter('.header__links ');
                    }
                }
            });
        }

        function closeMegaMenu(){
            let configMegaMenu = {
                link: '.global-nav__item',
                dropdown: '.global-nav__dropdown',
                button: '.global-nav__close',
                class: 'hide'
            }

            $(configMegaMenu.link).each(function(i,e){
               $(e).hover(function(){
                   $(e).find(configMegaMenu.dropdown).removeClass(configMegaMenu.class);
                });

               $(e).find(configMegaMenu.button).on('click', function(){
                   $(e).find(configMegaMenu.dropdown).addClass(configMegaMenu.class);
                });
            });
        }
    })

});






