import {isMobile} from "../../helpers";
import {throttle} from "throttle-debounce";
import Hunt from 'huntjs';

(function ($) {

    const BASE_CLASS = '.subsite-nav';

    const subsiteNav = $(BASE_CLASS);

    if (!subsiteNav.length) {
        return false;
    }

    const subsiteNavLists = $(`${BASE_CLASS}__list`);

    $('.subsite-nav__logo').on('click', function (e) {
        // use isMobile here to make function works after screen resize
        if (isMobile() == true) {
            e.preventDefault();
            $(this).parents('.subsite-nav').toggleClass('active');
            $('.subsite-nav__list').find('.active').removeClass('active');
        }
    })

    $('.subsite-nav__item .subsite-nav__link-trigger').on('click', function (e) {
        // use isMobile here to make function works after screen resize
        if (isMobile() == true) {
            e.preventDefault();
            const targetItem = $(this).closest('.subsite-nav__item');

            if (targetItem.hasClass('active')) {
                targetItem.removeClass('active');
                targetItem.find('.active').removeClass('active');

            } else {
                targetItem.toggleClass('active');
                targetItem.find('.subsite-nav__list').first().toggleClass('active');
                targetItem.siblings().removeClass('active');
                targetItem.siblings().find('.active').removeClass('active');
            }
        }
    })

    $('.subsite-nav__item--top.menu-item-has-children').hover((e) => {
        if (isMobile() == false) {
            $('.header-subsite').addClass('on-hover');
        }

    }, (e) => {
        if (isMobile() == false) {
            $('.header-subsite').removeClass('on-hover')
        }
    })

    function headerOutOfViewChecker() {
        new Hunt($('.header__grid'), {
            enter: function () {
                $('.onpage-nav').removeClass('nav-sticky-active');
            },
            leave: function () {
                $('.onpage-nav').addClass('nav-sticky-active');
            },
            // called every time the element appears and disappears from the viewport 
            persist: true,
            // trigger offset in pixels
            offset: 0,
            // interval that hunt events are throttled by in milliseconds
            throttleInterval: 100
        });

    }

    function subsiteNavOverflow(){

        let navShowWidth = $(window).width() - $('.subsite-nav__list--top').offset().left;
        let navWidth = 0;

        $('.subsite-nav__list--top > li').each(function(i, e){
            navWidth += parseInt($(e).outerWidth());
        })


        let offset = navShowWidth - navWidth;

        if (offset > 0) {
            $('.subsite-nav__indicator').hide();

        } else {
            $('.subsite-nav__prev').hide();
            $('.subsite-nav__list--top').width(navShowWidth);
            $('.subsite-nav, .subsite-nav__list--top').addClass('overflow');

        }


        $('.subsite-nav__next').on('click', function(e) {
            e.preventDefault();
            $('.subsite-nav__prev').show();
            let lastItemOverlapped = $('.subsite-nav__item--top:last-child').offset().left + 200 - $('.subsite-nav__next').offset().left;
            
            if(lastItemOverlapped > 0) {
                $('.subsite-nav__item--top').animate({
                    left: '-=100'
                }, 500);
            }
            
        });

        $('.subsite-nav__prev').on('click', function(e) {
            e.preventDefault();

            let firstItemOverlapped = $('.subsite-nav__item--top:nth(2)').offset().left - 100 - $('.subsite-nav__prev').offset().left;
            
             if(firstItemOverlapped < 0) {
                $('.subsite-nav__item--top').animate({
                    left: '+=100'
                }, 500);
             }
            
        });
    }

    function subsiteNavHeightHandler() {
        let heightArr = [];
        $('.subsite-nav__list--child').each(function () {
            heightArr.push($(this).outerHeight());
        })
        let maxHeight = Math.max(...heightArr);
        $('.header-subsite').css('--max-nav-height', maxHeight + 'px');
    }

    function dropdownLocationHandler() {
        $('.header-subsite .subsite-nav__list--child').each(function (el) {
            let distanceOfChildToLeftEnd = $(this).offset().left;

            let distanceOfParentToLeftEnd = $('.subsite-nav__list--top').offset().left;
            let offsetDistance = (distanceOfChildToLeftEnd - distanceOfParentToLeftEnd) * -1;
            let navParentWidth = $('.subsite-nav__list--top').outerWidth();

            $('.subsite-nav__list--top').css({
                '--subsite-nav__list-width': navParentWidth + 'px'
            })

            $(this).css({
                '--subsite-nav__list-offset': offsetDistance + 'px'
            })

        })
    }

    $(document).ready(function () {
        if (isMobile() == false) {
            dropdownLocationHandler();
        }
    });

    window.addEventListener('resize', subsiteNavHeightHandler);
    $(window).on('load resize', throttle(300, function() {
        subsiteNavOverflow();
    }));
    subsiteNavHeightHandler();
    headerOutOfViewChecker();

})(jQuery);


