import hoverintent from 'hoverintent';
import { isMobile } from "../../helpers";

(function ($) {

    const BASE_CLASS = '.onpage-nav';
    const ITEM_CLASS = `${BASE_CLASS}__item`;
    const TITLE_CLASS = `${BASE_CLASS}__title`;
    const ACTIVE_CLASS = 'on-hover';

    const onpageNav = $(BASE_CLASS);
    const topTitle = $(TITLE_CLASS);
    const onpageParents = onpageNav.find(`${ITEM_CLASS}--top.menu-item-has-children`);

    const topOffset = $('.breadcrumbs').length ? topTitle.outerHeight() + $('.breadcrumbs').outerHeight() : topTitle.outerHeight();

  
    // when on desktop
    onpageParents.each((index, item) => {    
        hoverintent($(item)[0], (e) => {
            // use isMobile here to make function works after screen resize
            if (isMobile() == false) { 
                onpageNav.css({
                    '--onpage-nav-width' :  onpageNav.outerWidth() + 'px', 
                    '--onpage-nav-top-offset': topOffset + 'px'
                });

                const parent = getItemParent(e.target);
                parent.addClass(ACTIVE_CLASS);
        }

        }, (e) => {
            if (isMobile() == false) { 
                const parent = getItemParent(e.target);
                parent.removeClass(ACTIVE_CLASS);
            }

        }).options({
            interval: 100,
            timeout: 300,
            sensitivity: 20
        })
    });
    

    // when on mobile 
    $('.onpage-nav__title').on('click',function(e){
        // use isMobile here to make function works after screen resize
       if (isMobile() == true) {  
            $(this).parent().toggleClass('active');
            $(this).siblings('.onpage-nav__list').toggleClass('active');
            $('.onpage-nav__list').find('.active').removeClass('active');
        }
    })

    $('.onpage-nav__item .onpage-nav__link-trigger').on('click', function(e) {
        // use isMobile here to make function works after screen resize
        if (isMobile() == true) {  
            const targetItem = $(this).closest('.onpage-nav__item');

            if(targetItem.hasClass('active')){
                targetItem.removeClass('active');
                targetItem.find('.active').removeClass('active');

            }else {
                targetItem.toggleClass('active');
                targetItem.find('.onpage-nav__list').first().toggleClass('active');
                targetItem.siblings().removeClass('active');
                targetItem.siblings().find('.active').removeClass('active');
            }
        }
    })


    // if an element in the viewport
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        
        return elementTop < viewportBottom;
    };

    // if an element touch footer
    $.fn.isTouchFooter = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight() + 1; // calculate include border height
        var footerTop = $('.footer').offset().top;
        
        return elementBottom > footerTop;
    };


     function getItemParent(item) {
        const parent = $(item).parents(`${ITEM_CLASS}`);
        
        return parent;

    }

    $(window).on('load scroll', function() {
        if($('.footer').isInViewport()) {
            onpageNav.css({
                '--onpage-sub-nav-height' :  $('aside').height() + 'px'
            });
        }else {
            onpageNav.css({
                '--onpage-sub-nav-height' :  '100vh'
            });
        }
    })

})(jQuery);