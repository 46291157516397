$(function() {

    function closeDropdown() {
        $('.dropdown-toggle.active').removeClass('active').find('.select-dropdown__menu').slideUp(200);
    }

    function toggleDropdown() {
        $('.dropdown-toggle').click(function (e) {
            var $this = $(this);

            // close active drop downs
            if (!$this.hasClass('active')) {
                closeDropdown();
            }

            // toggle this drop down
            $(this).toggleClass('active').find('.select-dropdown__menu').slideToggle(200);

            // only trigger once per click
            e.stopPropagation();
        });

        $(document).click(function () {
            $('.dropdown-toggle.active').removeClass('active').find('.select-dropdown__menu').slideToggle(200);
        });
    }



    if ($('.dropdown-toggle').length) {
        toggleDropdown();
    }

});



